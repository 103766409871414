<script setup lang="ts">
// @ts-expect-error The library doesn't use TS
import AvrisVueShare from 'avris-vue-share';

const props = defineProps<{
    title?: string;
    url?: string;
}>();
</script>

<template>
    <div class="mb-3">
        <p class="small mb-0">
            <Icon v="share" />
            <T>share</T><T>quotation.colon</T>
        </p>
        <AvrisVueShare
            :title="props.title"
            :url="props.url"
            :translations="{
                share: $t('share'),
                clipboard: $t('crud.copy'),
                logo: (network: string) => `${network} logo`,
            }"
        />
    </div>
</template>
